@import 'styles/variables';
@import 'styles/typography';

.new-feature-modal {
  display: flex;
  max-width: 420px !important;

  @media #{$breakpoint-lg-up} {
    max-width: 842px !important;
    width: auto !important;
    height: 304px !important;
  }

  &__content {
    span {
      padding-top: 0 !important;
    }
    @media #{$breakpoint-lg-up} {
      display: flex;
      span {
        overflow: visible !important;
      }
      padding-right: 50px !important;
    }
  }
}
