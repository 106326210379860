$rem-baseline: 16px;
$grid-columns: 12 !default;
$xxs-max: 375px !default;
$xs-max: 479px !default;
$sm-max: 768px !default;
$md-max: 991px !default;
$lg-max: 1440px !default;
$xl-max: 1920px !default;
$xs-start: ($xxs-max + 1);
$sm-start: ($xs-max + 1);
$md-start: ($sm-max + 1);
$lg-start: ($md-max + 1);
$xl-start: ($lg-max + 1);
$breakpoint-xxs-up: 'only screen and (min-width: #{$xs-start})';
$breakpoint-xs-only: 'only screen and (max-width: #{$xs-max})';
$breakpoint-sm-up: 'only screen and (min-width: #{$sm-start})';
$breakpoint-sm-only: 'only screen and (min-width: #{$sm-start}) and (max-width: #{$sm-max})';
$breakpoint-md-up: 'only screen and (min-width: #{$md-start})';
$breakpoint-md-only: 'only screen and (min-width: #{$md-start}) and (max-width: #{$md-max})';
$breakpoint-lg-up: 'only screen and (min-width: #{$lg-start})';
$breakpoint-lg-only: 'only screen and (min-width: #{$lg-start}) and (max-width: #{$lg-max})';
$breakpoint-xl-up: 'only screen and (min-width: #{$xl-start})';
$webflow-lg-breakpoint: 'only screen and (min-width: 1440px)';
$webflow-xl-breakpoint: 'only screen and (min-width: 1920px)';
$gutter: 0.63rem !default;
$content-well-max-width: 1120px !default; // Container width

$use-responsive-spacers: false; // Set to true for generation of responsive spacers (margin, padding) that depends on breakpoints
$use-responsive-text-align: false; // Set to true for generation of responsive text-align classes that depends on breakpoints

$font-geomanist: 'Geomanist webfont', sans-serif;
$color-primary-purple: #a200ed;
$color-secondary-blue: #006cff;
$color-black: #342f41;
$color-white: #fff;
$color-gray-1: #f9f9fa;
$color-gray-2: #f3f1f6;
$color-gray-3: #ebe8f1;
$color-gray-4: #dcd8e7;
$color-gray-5: #c0bacc;
$color-gray-6: #8a8597;
$color-gray-7: #5c5766;
$color-gray-8: #342f41;
$color-gray-9: #1c1a21;
$color-dark-grey: #716f80;
$color-medium-grey: #c0c0c0;
$color-light-grey: #dedede;
$color-craft: #f1ece8;
$color-light-craft: #f6f3f1;
$color-light-purple: #f4f2ff;
$color-dark-purple: #7500ac;
$color-ghost-purple: #f8f8fb;
$color-red: #e03a86;
$color-green: #21bf73;
$color-yellow: #ffb119;
$color-pop-up: rgba($color-black, 0.5);
$color-light-dark: #444;
$gradient-buttons-linear: linear-gradient(45deg, #324bf9 11%, #9409ee 84%);
$color-blue-super-light: #f4f2ff;
$blue-light: #d8d9ff;
$blue-super-light: #f4f2ff;

$shadow-subtle: 0px 1px 1px #5c576626;
$shadow-tiny: 0px 1px 4px #5c576626;
$shadow-small: 0px 2px 8px #5c576626;
$shadow-large: 0px 7px 17px #5c576626;
$shadow-button_hover: 0px 3px 8px #6c5ac640;
