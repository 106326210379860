@import 'styles/variables';

.confirmation-dialog {
  &__controls {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--inverted {
      flex-direction: column-reverse;
    }

    .btn {
      margin: 16px 0;
      flex-basis: 50%;

      @media #{$breakpoint-md-up} {
        margin: 0 10px;
      }
    }

    @media #{$breakpoint-md-up} {
      flex-direction: row;

      &--inverted {
        flex-direction: row-reverse;
      }
    }
  }
}
