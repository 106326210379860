@import 'styles/variables';

.new-badge {
  background: $gradient-buttons-linear;
  color: $color-white;
  font-size: 9px;
  line-height: 18px;
  text-align: center;
  border-radius: 8px;
  padding: 0 8px;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
}
