@import 'styles/variables';
@import 'styles/mixins/placeholder';

.form-textarea {
  display: block;
  margin: 0;

  &__title {
    margin: 0 0 24px;
  }

  &__label {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-left: 1px;
    margin-bottom: 12px;
    color: $color-gray-7;
    font-weight: 600;
  }

  &__field {
    @include placeholder {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $color-gray-7;
    }

    width: 100%;
    resize: none;
    border: 1px solid $color-gray-5;
    border-radius: 10px;
    padding: 12px 14px;
    font-size: 16px;
    font-weight: 600;
    color: $color-black;

    &:hover {
      border: 1px solid $color-gray-7;
    }

    &:focus {
      border-color: $color-gray-7;
      outline: 0;
    }
  }

  &__help-text {
    margin-left: 1px;
    font-weight: 12px;
    color: $color-gray-7;
  }

  &--error {
    .form-textarea__field {
      border-color: $color-red;
      margin-bottom: -6px;
    }
  }
}
