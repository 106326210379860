@import 'styles/variables';
@import 'styles/typography';

.agent-card {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 8px;
  justify-content: start;
  background-color: $color-light-purple;
  border-radius: 16px;
  padding: 40px;
  font-size: 15px;
  line-height: 23px;

  &__avatar {
    background: $gradient-buttons-linear;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    margin: 24px auto;

    .avatar {
      border: 0;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
    }
  }

  &__name {
    @include heading-2;

    display: grid;
    width: fit-content;
    justify-content: center;
    align-items: center;
    grid-gap: 16px;
    font-weight: 600;
    margin: 0 auto;

    &--online {
      grid-template-columns: 1fr 20px;
    }
  }

  &__online-indicator {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $color-green;
  }

  &__status {
    text-align: center;
  }

  &__field {
    margin: 24px 0;

    &-title {
      font-weight: 600;
      margin-bottom: 8px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
