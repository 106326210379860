@import 'variables';

.Toastify__toast-container--top-right {
  top: 60px;
  right: 0px;

  @media #{$breakpoint-md-up} {
    top: 82px;
    right: 0px;
  }
}

.Toastify__toast {
  font-family: $font-geomanist;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  border-radius: 8px !important;
  min-height: 44px;
  padding: 8px 16px;
  background: #fff;
  border: 1px solid #c0bacc;
  color: #342f41;

  .Toastify__close-button {
    color: #8a8597;
    margin-top: 3px;
  }

  .Toastify__progress-bar {
    height: 4px;
    background-color: #ece8f1;
  }
}

.Toastify__toast--success {
  background: #fff;
  color: #342f41;
  border: 1px solid #56b68c;
}

.Toastify__toast--info {
  background: #fff;
  color: #342f41;
  border: 1px solid #342f41;
}

.Toastify__toast--warning,
.Toastify__toast--error {
  background: #fff;
  color: #342f41;
  border: 1px solid #e03a86;
}
