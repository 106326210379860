@import 'styles/variables';
@import 'styles/animations';

.uploader_modal {
  max-width: 520px !important;
}

.file-uploader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  input[type='file'] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &__modal {
    &-title {
      margin: 0 0 20px;
      text-align: left;
    }

    &-filename {
      margin: 24px 0;
      word-break: break-all;
      text-align: left;
      display: grid;
      grid-gap: 4px;
      grid-auto-flow: row;
      align-items: center;
      justify-content: space-between;
    }
    &-button-box {
      display: flex;
    }
  }
}
