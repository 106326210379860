@import 'styles/variables';
@import 'styles/shadows';

.form-checkbox {
  &__wrapper {
    display: inline-block;
    position: relative;
    padding-left: 36px;
    padding-top: 4px;
    margin-bottom: 2px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    color: $color-gray-6;
    font-weight: 500;

    &:hover {
      color: $color-gray-7;
    }
  }

  &__mark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: $color-white;
    border: 1px solid $color-gray-5;
    border-radius: 4px;
    color: $color-primary-purple;

    &::after {
      content: url('/icons/icon-24-checkbox.svg');
      position: absolute;
      display: none;
      left: -1px;
      top: -2px;
      transform: scale(0.8);
    }

    &:hover {
      border: 1px solid $color-gray-7;
      box-shadow: 0px 2px 6px #8a85973d;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .form-checkbox__mark {
      border-color: $color-gray-8;

      &::after {
        display: block;
      }
    }
  }

  &--disabled {
    .form-checkbox__mark {
      border-color: $color-light-grey;

      &::after {
        border-color: $color-light-grey;
      }
    }

    &:hover input ~ .form-checkbox__mark {
      box-shadow: none;
    }
  }
}
