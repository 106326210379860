@import 'styles/variables';
@import 'styles/animations';

.loader {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-circle {
    margin-top: -100px;
    position: relative;
    background-color: #009688;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: conic-gradient(#a200ed 0deg, #8e0eef 75deg, #5037f7 172deg, transparent 295deg, transparent 360deg);
    animation: rotate 1s linear infinite;
  }

  .loader-circle:before,
  .loader-circle:after {
    content: '';
    position: absolute;
    border-radius: 50%;
  }

  .loader-circle:before {
    width: 42px;
    height: 42px;
    top: 4px;
    left: 4px;
    background-color: #fff;
  }

  .loader-circle:after {
    height: 4px;
    width: 4px;
    background-color: #a200ed;
    top: 0;
    left: 22px;
  }

  &__icon {
    margin: auto;
    animation: rotate 2s linear infinite;
  }

  &--fullscreen {
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
