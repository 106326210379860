@mixin shadow-5 {
  box-shadow: 0 20px 50px -9px rgb(0 4 119 / 30%);
}

@mixin shadow-4 {
  box-shadow: 0 1px 10px 2px rgb(60 55 89 / 7%);
}

@mixin shadow-3 {
  box-shadow: 0 4px 20px -10px rgb(52 47 65 / 40%);
}

@mixin shadow-search-bar {
  box-shadow: 0 1px 6px -3px rgb(0 0 0 / 50%);
}

@mixin shadow-cards {
  box-shadow: 0px 1px 4px #5c576626;
}

@mixin shadow-buttons-hover {
  box-shadow: 4px 4px 20px -12px rgb(113 111 128 / 50%);
}

@mixin shadow-checkbox {
  box-shadow: 0 4px 20px 2px rgb(52 47 65 / 20%);
}
