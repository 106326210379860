@import '../variables';
@import 'mixins';
@import 'visibility-mixins';

$breakpoints-all: (
  $breakpoint-xs-only,
  $breakpoint-sm-up,
  $breakpoint-sm-only,
  $breakpoint-md-up,
  $breakpoint-md-only,
  $breakpoint-lg-up,
  $breakpoint-lg-only,
  $breakpoint-xl-up
);
$breakpoint-ups: ($breakpoint-sm-up, $breakpoint-md-up, $breakpoint-lg-up, $breakpoint-xl-up);
$breakpoint-all-prefixes: ('xs', 'xs-only', 'sm', 'sm-only', 'md', 'md-only', 'lg', 'lg-only', 'xl');
$breakpoint-up-prefixes: ('xs', 'sm', 'md', 'lg', 'xl');
$breakpoint-up-prefixes-map: (
  xs: $breakpoint-xs-only,
  sm: $breakpoint-sm-up,
  md: $breakpoint-md-up,
  lg: $breakpoint-lg-up,
  xl: $breakpoint-xl-up
);

.row {
  @include row;

  margin: 0 auto;
}

.row.no-wrap {
  flex-wrap: nowrap;
}

.row.reverse {
  @include row-reverse;
}

.row.natural-height {
  @include row-natural-height;
}

.row.flex-column {
  @include row-flex-column;
}

.col {
  @include col;
}

.col.reverse {
  @include col-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *

@mixin col-factory($thisPrefix) {
  @for $i from 1 through $grid-columns {
    .col-#{$thisPrefix}-#{$i} {
      box-sizing: border-box;
      flex-basis: calc(100% / $grid-columns * $i);
      max-width: calc(100% / $grid-columns * $i);
      padding: $gutter;
    }

    .col-#{$thisPrefix}-offset-#{$i} {
      margin-left: calc(100% / $grid-columns * $i);
    }
  }

  .col-#{$thisPrefix} {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: $gutter;
  }

  .row.start-#{$thisPrefix} {
    justify-content: flex-start;
  }

  .row.center-#{$thisPrefix} {
    justify-content: center;
  }

  .row.end-#{$thisPrefix} {
    justify-content: flex-end;
  }

  .row.top-#{$thisPrefix} {
    align-items: flex-start;
  }

  .row.middle-#{$thisPrefix} {
    align-items: center;
  }

  .row.bottom-#{$thisPrefix} {
    align-items: flex-end;
  }

  .row.around-#{$thisPrefix} {
    justify-content: space-around;
  }

  .row.between-#{$thisPrefix} {
    justify-content: space-between;
  }

  .first-#{$thisPrefix} {
    order: -1;
  }

  .last-#{$thisPrefix} {
    order: 1;
  }
} // Mixin to run inside of for loop - creates col/breakpoint classes

@for $i from 1 through length($breakpoint-up-prefixes) {
  $thisPrefix: nth($breakpoint-up-prefixes, $i);

  @if $thisPrefix == 'xs' {
    @include col-factory($thisPrefix);
  } @else if $thisPrefix == 'sm' {
    @media #{$breakpoint-sm-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == 'md' {
    @media #{$breakpoint-md-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == 'lg' {
    @media #{$breakpoint-lg-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == 'xl' {
    @media #{$breakpoint-xl-up} {
      @include col-factory($thisPrefix);
    }
  }
}

.container {
  max-width: $content-well-max-width;
  margin: 0 auto;
  padding: 0;
}

.container-736 {
  @extend .container;

  max-width: 736px;
}

.container-form-420 {
  @extend .container;

  max-width: 420px;
}

.col-gutter-lr {
  padding: 0 $gutter;
}

.col-gutter-tb {
  padding: $gutter 0;
}

.col-no-gutter {
  padding: 0;
}

.col-gutter {
  padding: $gutter;
}

.col-center {
  text-align: center;
}

.col-flex {
  display: flex;
}

.row-no-gutter-wrapper {
  margin-left: -#{$gutter};
  margin-right: -#{$gutter};
}

.row-no-gutter-tb-wrapper {
  margin-top: -#{$gutter};
  margin-bottom: -#{$gutter};
}

.app-page-container {
  margin: 0 auto;
  padding: 0 24px;

  &.fullWidth {
    max-width: unset;
    width: '100%';
  }

  @media #{$breakpoint-md-up} {
    max-width: $content-well-max-width;
  }

  @media #{$breakpoint-xl-up} {
    padding: 0 24px;
  }
}
