@import '../variables';
@import '../grid/grid';

$spacer: $gutter;
$spacers: 4;

@mixin spacing($breakpoint-prefix: '') {
  /* stylelint-disable */
  @each $prop, $abbrev in (margin: m, padding: p) {
    @for $size from 0 through $spacers {
      $spacing-size: $spacer * $size;
      $prefix: $breakpoint-prefix;

      @if $breakpoint-prefix != '' {
        $prefix: $breakpoint-prefix + '-';
      }

      .#{$abbrev}-#{$prefix}#{$size} {
        #{$prop}: $spacing-size !important;
      }

      .#{$abbrev}t-#{$prefix}#{$size} {
        #{$prop}-top: $spacing-size !important;
      }

      .#{$abbrev}r-#{$prefix}#{$size} {
        #{$prop}-right: $spacing-size !important;
      }

      .#{$abbrev}b-#{$prefix}#{$size} {
        #{$prop}-bottom: $spacing-size !important;
      }

      .#{$abbrev}l-#{$prefix}#{$size} {
        #{$prop}-left: $spacing-size !important;
      }

      .#{$abbrev}x-#{$prefix}#{$size} {
        #{$prop}-left: $spacing-size !important;
        #{$prop}-right: $spacing-size !important;
      }

      .#{$abbrev}y-#{$prefix}#{$size} {
        #{$prop}-top: $spacing-size !important;
        #{$prop}-bottom: $spacing-size !important;
      }
    }
  }
  /* stylelint-enable */
}

@include spacing;

@if $use-responsive-spacers {
  @each $prefix in $breakpoint-up-prefixes {
    @media #{map-get($breakpoint-up-prefixes-map, $prefix)} {
      @include spacing($prefix);
    }
  }
}

.m-auto {
  margin: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
