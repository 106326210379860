@import 'styles/variables';
@import 'styles/shadows';
@import 'styles/typography';

.files-list {
  max-width: 640px;

  &__item {
    @include shadow-cards;

    background-color: $color-light-purple;
    border: 1px solid $color-craft;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
