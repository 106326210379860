@import 'styles/variables';
@import 'styles/typography';

.form-select {
  .select {
    &__input input {
      color: #342f41 !important;
      font-size: 16px !important;
      font-weight: 600 !important;
    }

    &__control {
      min-height: 48px;
      background-color: transparent;
      border-width: 1px;
      border-radius: 10px;
      border-color: $color-gray-5;
      padding: 0 18px;

      .select__value-container {
        padding: 0;
        min-height: 48px;

        .select__placeholder {
          left: -4px;
          padding: 0 4px;
          background-color: #fff;
        }

        &--has-value {
          .select__placeholder {
            top: -1px;
            color: $color-dark-grey;
            font-weight: 500;
            font-size: 12px;
            padding: 0 4px;
            background-color: #fff;
          }
        }
      }

      &:hover {
        border-color: $color-gray-7;
      }

      &:focus-within {
        border-color: $color-gray-7;
        outline: none;
        border-radius: 10px;
        box-shadow: 0px 2px 6px #5c57661a;
      }

      &--is-disabled {
        opacity: 0.3;
      }

      &--has-value {
        .select__placeholder {
          top: -1px;
          color: $color-dark-grey;
          font-weight: 500;
          font-size: 12px;
          padding: 0 4px;
          background-color: #fff;
          left: 0;
        }
      }

      &--is-focused {
        box-shadow: none;
        border-color: $color-light-purple;

        .select__placeholder {
          top: -1px;
          color: $color-dark-grey;
          font-weight: 500;
          font-size: 12px;
          padding: 0 4px;
          background-color: #fff;
          left: 0;
        }
      }
    }

    &__value-container {
      overflow-y: initial !important;

      &--is-multi {
        margin-left: -11px;
      }
    }

    &__input {
      font-size: 18px;

      input {
        color: $color-black !important;
      }
    }

    &__option {
      padding: 12px 24px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        height: 1px;
        background-color: rgba($color-white, 0.15);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &--is-selected {
        background-color: $color-gray-2;
        color: $color-black;

        &:hover {
          background-color: $color-gray-2;
          color: $color-black;
        }
      }

      &--is-focused {
        background-color: $color-gray-2;
        color: $color-black;
      }
    }
  }

  .select__value-container--is-multi {
    .select__placeholder {
      left: 8px !important;
    }
  }

  &--error {
    .select__control {
      border-color: $color-red;
    }

    .select__placeholder {
      color: $color-red;
    }
  }

  .form-error {
    margin-top: 4px;
    margin-left: 20px;
  }
}

.form-select .rounded-select {
  margin: 0;

  .select {
    &__placeholder {
      color: #222;
    }

    &:not(.select__value-container--has-value) {
      .select__placeholder {
        font-size: 15px;
      }
    }

    &__menu {
      border-radius: 10px;
      margin-top: 1px;
      box-shadow: 0 1px 4px 0 rgb(60 55 89 / 10%);
      max-height: 345px;
      padding: 0;
    }

    &__menu-list {
      padding: 0;
      border-radius: 10px;
    }

    &__control {
      background-color: #fff;
      border-width: 0;
      border-radius: 10px;
      box-shadow: 0 1px 4px 0 rgb(60 55 89 / 10%);

      &:hover {
        cursor: pointer;
        box-shadow: 0 0 0 3px rgb(0 38 255 / 8%), 0 1px 4px 0 rgb(60 55 89 / 10%);
      }
    }

    &__indicators {
      color: #222;
    }

    &__option {
      border-radius: 0;
      color: #222;
      padding: 10px 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }

      &:hover {
        background-color: $color-light-purple;
        color: #222;
      }

      &--is-selected {
        background-color: $color-light-purple;
        color: #222;

        &:hover {
          background-color: $color-light-purple;
          color: #222;
        }
      }
    }

    &__value-container {
      min-height: 52px;
    }
  }
}

.form-select {
  .select__menu {
    border-radius: 10px;
    margin-top: 4px;
  }
}
