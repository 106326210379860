@import 'styles/variables';
@import 'styles/shadows';
@import 'styles/typography';

$squareroot-of-two: 1.414213562;
$target-size: 16px;
$size: $target-size / $squareroot-of-two;

$tooltip-bg: #fff;
$tooltip-padding: 15px 20px;
$tooltip-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.75);
$tooltip-border-width: 1px;
$tooltip-border: $tooltip-border-width solid #6c5ac626;

.tippy-box {
  @include shadow-cards;
  @include body-small;

  max-width: 360px !important;
  background-color: $color-white !important;
  border-radius: 8px;
  color: $color-dark-grey !important;
  &[data-placement^='left'] {
    &:after {
      @include shadow-cards;
      content: '';
      width: $size;
      height: $size;
      position: absolute;
      right: -$size/2;
      top: 50%;
      margin-top: -$size/2;
      background-color: $tooltip-bg;
      transform: rotate(45deg);
      border: $tooltip-border;
    }
  }

  &[data-placement^='right'] {
    &:after {
      @include shadow-cards;
      content: '';
      width: $size;
      height: $size;
      position: absolute;
      left: -$size/2;
      top: 50%;
      margin-top: -$size/2;
      background-color: $tooltip-bg;
      transform: rotate(45deg);
      border: $tooltip-border;
    }
  }

  &[data-placement^='bottom'] {
    &:before {
      @include shadow-cards;
      content: '';
      width: $size;
      height: $size;
      position: absolute;
      top: -$size/2;
      left: 50%;
      margin-left: -$size/2;
      background-color: $tooltip-bg;
      transform: rotate(45deg);
      border: $tooltip-border;
    }
  }

  &[data-placement^='left'] > .tippy-content {
    &::after {
      content: '';
      width: $size;
      height: $size;
      position: absolute;
      right: -($size / 2) + $tooltip-border-width * ($squareroot-of-two - 1);
      top: 50%;
      margin-top: -$size/2;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }

  &[data-placement^='right'] > .tippy-content {
    &::after {
      content: '';
      width: $size;
      height: $size;
      position: absolute;
      left: -($size / 2) + $tooltip-border-width * ($squareroot-of-two - 1);
      top: 50%;
      margin-top: -$size/2;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }

  &[data-placement^='bottom'] > .tippy-content {
    &::before {
      content: '';
      width: $size;
      height: $size;
      position: absolute;
      top: -($size / 2) + $tooltip-border-width * ($squareroot-of-two - 1);
      left: 50%;
      margin-left: -$size/2;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }

  .tippy-content {
    background-color: $tooltip-bg;
    padding: $tooltip-padding;
    position: relative;
    border: $tooltip-border;
    z-index: 1;
    border-radius: 8px;

    * {
      position: relative;
      margin: 15px 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
