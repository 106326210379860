.form-input-phone {
  .PhoneInput {
    display: block;
  }

  .PhoneInputCountry {
    position: absolute;
    top: 19px;
    left: 19px;
    z-index: 10;

    .PhoneInputCountryIcon--border {
      box-shadow: none;
      border-radius: 2px;
      overflow: hidden;
    }
  }

  .PhoneInputCountrySelectArrow {
    margin-left: 8px;
  }

  .form-input__input {
    padding-left: 66px;
  }

  .form-input__label--placeholder {
    left: 66px;
  }
}
