@import 'styles/variables';

.progress-indicator {
  width: 100%;
  height: 8px;
  background-color: $color-light-purple;
  border-radius: 4px;
  display: flex;
  flex-wrap: nowrap;

  &__progress {
    background: $color-primary-purple;
    border-radius: 4px;
    height: 8px;
  }
}
