@import 'styles/variables';

.form-number-picker {
  position: relative;

  .form-input {
    &__wrapper {
      display: flex;
      flex-direction: column-reverse;
    }

    &__input {
      padding-left: 48px;
      padding-right: 48px;
      text-align: center;
      border-radius: 10px;
      border: 1px solid $color-gray-5;

      &:hover {
        border: 1px solid $color-gray-7;
      }

      &:focus {
        border: 1px solid $color-gray-7;
      }
    }
  }

  &__button {
    background-color: $color-light-purple;
    border: 0;
    border-radius: 1px;
    color: $color-black;
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% + 12px);
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;

    &:disabled {
      background-color: $color-ghost-purple;
      color: $color-medium-grey;
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
      opacity: 0.9;
    }

    &--add {
      right: 24px;
    }

    &--remove {
      left: 24px;
    }
  }
}

.form-number-picker-control {
  position: relative;
  padding: 8px 0;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .custom-label {
    margin-right: 14px;
    font-weight: 600;
    color: $color-gray-8;
    font-size: 16px;
  }

  .custom-legend {
    margin-left: 8px;
    font-weight: 600;
    margin-right: 16px;
    color: $color-gray-6;
    font-size: 14px;
  }

  .form-input {
    padding: 0;

    &__input {
      padding: 0;
      margin-right: 4px;
      text-align: right;
      width: 36px;
      border-radius: 0;
      border: none;
      outline: 0;

      &:hover {
        border: none;
      }

      &:focus {
        border: none;
        outline: 0 !important;
        box-shadow: none;
      }
      &:focus-within {
        border: none;
        outline: 0 !important;
        border-radius: 0;
        box-shadow: none;
      }
    }
  }

  &__button {
    background-color: $color-white;
    border: 1px solid $color-gray-5;
    border-radius: 50%;
    color: $color-black;
    width: 32px;
    height: 32px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    flex: 0 0 32px;
    z-index: 2;
    cursor: pointer;

    &:disabled {
      background-color: $color-ghost-purple;
      color: $color-medium-grey;
      cursor: not-allowed;
    }
  }
}
