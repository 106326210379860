@import 'styles/variables';

.form-error {
  color: $color-red;
  text-align: left;
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;

  &--centered {
    text-align: center;
  }
}

.form-input__input__fused .form-error {
  color: $color-dark-grey;
  text-align: left;
  font-size: 12px;
  line-height: 16px;

  &--centered {
    text-align: center;
  }
}
