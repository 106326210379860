$icon-base-size: 1rem;
$icon-variants: (
  2x: $icon-base-size * 2,
  6x: $icon-base-size * 4,
  20x: 100px
);

.icon {
  width: $icon-base-size;
  height: $icon-base-size;

  /* we use both "color" and "fill" properties because some svg icons don't contain the "fill" attribute
    and hence svgo doesn't add "currentColor" attribute to them */
  color: inherit;
  fill: currentcolor;

  @each $class-modified, $size in $icon-variants {
    &--#{$class-modified} {
      width: $size;
      height: $size;
    }
  }
}

// Just for example
.transition {
  color: black;
  fill: black;
  transition: 0.2s linear fill;

  &:hover {
    color: red;
    fill: red;
  }
}
