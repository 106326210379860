@import 'styles/fonts';
@import 'styles/cards';
@import 'styles/grid/grid';
@import 'styles/grid/visibility';
@import 'styles/typography';
@import 'styles/mixins/remify';
@import 'styles/toast';
@import 'styles/slider-slick';
@import './utils/spacing';
@import './utils/text';
@import 'styles/shared';

html {
  @include rem-baseline(100%);

  text-size-adjust: none;
}

body {
  font-size: 15px;
  line-height: 23px;
  font-family: $font-geomanist;
  font-weight: 400;
  color: $color-black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table {
  td {
    padding: 8px;
  }
}

* {
  appearance: none !important;
  box-sizing: border-box;
}
