@mixin placeholder {
  &::placeholder {
    @content;
  }

  &::input-placeholder {
    @content;
  }

  &:placeholder {
    @content;
  }

  &:input-placeholder {
    @content;
  }
}
