@import 'styles/variables';
@import 'styles/shadows';

.form-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 16px;

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-dark-grey;
    transition: 0.4s;
    border-radius: 34px;

    &::before {
      @include shadow-cards;

      content: '';
      position: absolute;
      height: 24px;
      width: 24px;
      border: 1px solid $color-dark-grey;
      border-radius: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $color-white;
      transition: 0.4s;
      transition: 0.4s;
    }

    &:hover {
      @include shadow-checkbox;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .form-switch__slider {
      background-color: rgba($color-secondary-blue, 0.3);

      &::before {
        background-color: $color-secondary-blue;
      }
    }

    &:focus + .form-switch__slider {
      box-shadow: 0 0 1px $color-secondary-blue;
    }

    &:checked + .form-switch__slider::before {
      transform: translate(12px, -50%);
      border-color: $color-secondary-blue;
    }
  }
}
