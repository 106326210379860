.share-buttons {
  &__title {
    margin: 0 0 24px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__button {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    margin: 8px;

    svg,
    img {
      width: 48px;
      height: 48px;
    }
  }
}
