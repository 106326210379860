@import 'styles/variables';
@import 'styles/shadows';
@import 'styles/mixins/placeholder';

.search-bar {
  @include shadow-search-bar;

  border-radius: 32px;
  border: 1px solid $color-light-craft;
  display: flex;
  padding: 3px 4px;
  background-color: $color-white;

  .form-input {
    display: flex;
    width: 100%;
    margin: 0;

    &__wrapper {
      display: flex;
      width: 100%;
    }

    &__input {
      @include placeholder {
        color: $color-dark-grey;
        font-weight: 600;
      }

      width: 100%;
      border: 0;
    }
  }

  &__button {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;

    .btn__inner {
      width: 28px;
      height: 28px;
    }

    &-icon {
      color: $color-white;
    }
  }
}
