@import '/styles/variables';
@import '/styles/shadows';

.form-radio {
  &__wrapper {
    display: block;
    position: relative;
    padding-left: 36px;
    padding-top: 3px;
    margin-bottom: 2px;
    cursor: pointer;
    font-size: 16px;
    user-select: none;
    line-height: 20px;
    color: $color-gray-6;
    font-weight: 500;

    &:hover {
      color: $color-gray-7;
    }
  }

  &__mark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid $color-gray-5;
    border-radius: 50%;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      background: transparent linear-gradient(196deg, #a200ed 0%, #006cff 100%) 0% 0% no-repeat padding-box;
      border-radius: 50%;
    }

    &:hover {
      border: 1px solid $color-gray-7;
      box-shadow: 0px 2px 6px #8a85973d;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .form-radio__mark {
      border-color: $color-gray-8;

      &::after {
        display: block;
      }
    }
  }
}
