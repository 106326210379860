@import 'styles/variables';
@import 'styles/cards';
@import 'styles/typography';

.file-card {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__name {
    font-size: 15px;
    font-weight: bold;
    display: inline-grid;
    grid-auto-flow: column;
    grid-gap: 8px;
    align-items: center;
    word-break: break-all;
    color: inherit;
    text-decoration: none;
  }

  &__button {
    flex-shrink: 0;
    margin-left: 24px;

    .loader {
      margin: auto;
      height: 22px;
    }

    .loader__icon {
      width: 22px !important;
      height: 22px !important;
    }

    > .btn__inner {
      background-color: $color-light-purple !important;
      padding: 0 !important;
    }
  }
}
