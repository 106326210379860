@import 'styles/variables';

.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.wrap-modal {
  position: relative;
}

.modal-container {
  margin: 0 auto;
  padding: 45px 35px;
}

.modal-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 1001;
  background-color: $color-pop-up;

  &--disabled {
    background: transparent;
    width: fit-content;
    height: fit-content;
  }
}

.modal {
  &__wrap {
    position: relative;
    margin: auto;
    width: calc(100% - 20px);
    max-width: 736px;
    border: 1px solid transparent;

    &:focus {
      outline: none;
    }
  }

  &__body {
    max-height: 80vh;
    overflow: auto;
    padding: 24px;
    background-color: $color-white;
    border-radius: 16px;
  }

  &__close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    padding: 0;
    border: 0;
    background: transparent;
    color: $color-dark-grey;
    font-size: 20px;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
