@import 'styles/variables';

.control-button {
  width: 100%;

  &--centered {
    margin: 0 auto;
  }

  &--large {
  }

  &--responsive {
    max-width: 135px;

    @media #{$breakpoint-xxs-up} {
      max-width: 160px;
    }

    @media #{$breakpoint-md-up} {
    }
  }

  &--fullWidth {
    max-width: none;
    width: 100%;
  }
}
