@import 'styles/variables';
@import 'styles/mixins/placeholder';

@mixin placeholderActive {
  top: 0px;
  left: 14px !important;
  font-weight: 500;
  font-size: 12px;
  padding: 0 4px;
  background-color: #fff;
  color: $color-gray-6;
}

.form-input {
  display: block;
  position: relative;
  margin: 0;

  &__wrapper {
    position: relative;

    &--range {
      padding: 0 8px;
    }
  }

  &__label {
    display: block;
    font-size: 12px;
    color: $color-gray-7;

    &--placeholder {
      position: absolute;
      top: 50%;
      left: 18px;
      font-size: 16px;
      transform: translateY(-50%);
      z-index: 1;
      font-weight: 600;
      margin-left: 0;
      transition: all ease-out 0.1s;

      &-filled {
        @include placeholderActive;
      }
    }

    &--range {
      position: initial;
      margin-bottom: 20px;
    }

    &--compact {
      position: absolute;
      top: 50%;
      left: 10px;
      font-size: 14px;
      transform: translateY(-50%);
      z-index: 1;
      margin-left: 0;
      transition: all ease-out 0.1s;
    }
  }

  &__input {
    transition: all ease-out 0.1s;

    @include placeholder {
      color: $color-gray-6;
    }

    border-radius: 10px;

    &__fused {
      margin: 0;
      position: relative;

      &--first {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        &:hover,
        &:focus {
          z-index: 1;
        }
      }

      &--last {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        top: -1px;

        &:hover,
        &:focus {
          z-index: 1;
        }
      }
    }

    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding-left: 18px;
    padding-right: 18px;
    color: $color-black;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid $color-gray-5;
    font-family: $font-geomanist;

    &__compact {
      height: 32px;
      font-size: 14px;
      padding-left: 10px;
      padding-right: 10px;
      color: $color-gray-9;
    }

    &[type='password'] {
      padding-right: 48px;
    }

    &[type='number'] {
      -moz-appearance: textfield !important; /*To remove arrows in FireFox browser*/

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
    }

    &[type='time']::-webkit-calendar-picker-indicator {
      display: none;
    }

    &:not([value='']),
    &:-webkit-autofill {
      + .form-input__label--placeholder {
        @include placeholderActive;
      }

      + .form-input__label--compact {
        opacity: 0;
      }
    }

    &:hover {
      border: 1px solid $color-gray-7;
    }

    &:focus {
      outline: none;
      border-color: $color-gray-7;

      + .form-input__label--placeholder {
        @include placeholderActive;
      }

      + .form-input__label--compact {
        opacity: 0;
      }
    }

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 50px $color-white !important;
      -webkit-text-fill-color: $color-black !important;
      color: $color-black !important;
    }
  }

  &__password-toggle {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    color: $color-dark-grey;
    z-index: 4;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  &__error {
    margin-top: 8px;
    margin-left: 1px;
  }

  &--labeled {
    margin: 0px;
  }

  &--error {
    .form-input__input {
      border-color: $color-red;

      &:focus {
        + .form-input__label--placeholder {
          @include placeholderActive;
          color: $color-red;
        }

        + .form-input__label--compact {
          opacity: 0;
        }
      }
    }

    .form-input__label--placeholder {
      color: $color-red;
    }
  }
}
