@import 'styles/variables';
@import 'styles/typography';

.agent-user {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 8px;
  justify-content: start;

  &__avatar {
    border: 0 !important;
    width: 24px !important;
    height: 24px !important;

    &--online::after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $color-green;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__name {
    @include body-small;

    font-weight: 600;
    margin-right: auto;
  }
}
