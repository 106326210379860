.img-cropper {
  margin: 24px 0;
  max-height: 50vh;

  &__button {
    margin: 0 auto 8px !important;
  }
}

.ReactCrop__image {
  max-height: 50vh;
}
