@import 'styles/variables';
@import 'styles/typography';
@import 'styles/animations';

.btn {
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s linear all;
  border: 0;
  font-weight: 600;
  border-radius: 26px;
  position: relative;
  line-height: 24px;
  text-align: center;

  &:focus {
    outline: none; // Please add focus styles to all button variants
  }

  &__inner {
    transition: 0.2s linear all;
  }

  &--primary {
    @include body-large;

    height: 48px;
    background-image: $gradient-buttons-linear;
    color: $color-white;

    &:hover {
      opacity: 1;
      box-shadow: 0px 2px 12px #6c5ac633;
    }

    &:active {
      opacity: 0.85;
    }

    &:disabled {
      background: $color-light-grey;
      cursor: not-allowed;
      transform: translate(0, 0);

      &:hover {
        opacity: 1;
        transform: translate(0, 0);
      }
    }

    &.btn--sm {
      @include body-small;

      line-height: 18px;
      height: 38px;
      padding: 0 22px;
    }
  }

  &--outline {
    display: inline-flex;
    align-items: stretch;
    padding: 1px;

    .btn__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: $color-black;
      background-color: $color-white;
      padding: 10px 14px;
      border-radius: 26px;
    }

    &:disabled {
      background-color: #c0bacc;
      cursor: not-allowed;

      &:hover {
        background-color: #c0bacc;
        box-shadow: none;
      }

      .btn__inner {
        color: $color-light-grey;
        cursor: not-allowed;

        &:hover {
          background-color: $color-white;
        }
      }
    }

    &.btn--sm {
      .btn__inner {
        @include body-small;

        padding: 7px 22px;
      }
    }
  }

  &--text {
    background-color: transparent;
    padding: 0;
    border-radius: 0;
    transition: 0.2s linear background;
    width: fit-content;

    .btn__inner {
      @include gradient-text;
    }

    &:hover {
      @include gradient-text-reset;

      color: $color-black;
      filter: grayscale(100%);
    }

    &:active {
      .btn__inner {
        @include gradient-text-reset;

        color: $color-primary-purple;
      }
    }

    &:disabled {
      .btn__inner {
        @include gradient-text-reset;

        color: $color-light-grey;
        cursor: not-allowed;
      }
    }

    &.btn--icon {
      display: inline-grid;
      grid-auto-flow: column;
      align-items: center;
      grid-gap: 8px;
    }
  }

  &--link {
    background-color: transparent;
    border: 0;
    font-size: inherit;
    line-height: inherit;
    color: $color-black;
    text-decoration: underline;

    &.btn--icon {
      .btn__inner {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        grid-gap: 8px;
      }
    }
  }

  &--circle {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $color-gray-5;
  }

  &--circle:hover {
    background-color: $color-gray-7;
    box-shadow: 0px 2px 6px #8a85973d;
  }

  &--fluid {
    width: 100%;
  }
}
