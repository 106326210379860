.form-money {
  position: relative;

  &__currency {
    position: absolute;
    top: 15px;
    left: 24px;
    font-weight: 600;
    z-index: 1;
  }

  .form-input__input {
    padding-left: 36px;
  }

  .form-input__label--placeholder {
    top: -10px;
    left: 24px !important;
    font-weight: 500;
    font-size: 12px;
  }
}
