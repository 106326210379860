@import 'shadows';
@import 'variables';

@mixin default-card {
  @include shadow-cards;

  padding: 24px;
  border-radius: 10px;
  border: 1px solid #5c576626;

  @media #{$breakpoint-md-up} {
    padding: 32px;
  }
}

@mixin default-card-responsive {
  @include shadow-cards;

  padding: 24px;
  border-radius: 10px;

  @media #{$breakpoint-md-up} {
    padding: 32px;
  }
}

@mixin card-border {
  @include shadow-cards;

  padding: 24px;
  border-radius: 10px;
  border: 1px solid #5c576626;

  @media #{$breakpoint-md-up} {
    padding: 32px;
  }
}

.card--default {
  @include default-card;
}

.card--default--responsive {
  @include default-card-responsive;
}
