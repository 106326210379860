@import 'variables';

@mixin heading-font {
  font-family: $font-geomanist;
  font-weight: 600;
}

@mixin heading-1 {
  @include heading-font;

  font-size: 36px;
  line-height: 42px;

  @media #{$breakpoint-md-up} {
    font-size: 44px;
    line-height: 52px;
  }

  @media #{$breakpoint-xl-up} {
    font-size: 56px;
    line-height: 64px;
  }
}

@mixin heading-2 {
  @include heading-font;

  font-size: 32px;
  line-height: 38px;

  @media #{$breakpoint-md-up} {
    font-size: 38px;
    line-height: 46px;
  }

  @media #{$breakpoint-xl-up} {
    font-size: 46px;
    line-height: 54px;
  }
}

@mixin heading-3 {
  @include heading-font;

  font-size: 28px;
  line-height: 34px;

  @media #{$breakpoint-md-up} {
    font-size: 30px;
    line-height: 38px;
  }

  @media #{$breakpoint-xl-up} {
    font-size: 38px;
    line-height: 46px;
  }
}

@mixin heading-4 {
  @include heading-font;

  font-size: 24px;
  line-height: 32px;

  @media #{$breakpoint-md-up} {
    font-size: 24px;
    line-height: 33px;
  }

  @media #{$breakpoint-xl-up} {
    font-size: 30px;
    line-height: 38px;
  }
}

@mixin heading-5 {
  font-size: 20px;
  line-height: 26px;

  @media #{$breakpoint-md-up} {
    font-size: 22px;
    line-height: 30px;
  }

  @media #{$breakpoint-xl-up} {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin heading-6 {
  font-size: 18px;
  line-height: 27px;

  @media #{$breakpoint-md-up} {
    font-size: 18px;
    line-height: 24px;
  }

  @media #{$breakpoint-xl-up} {
    font-size: 20px;
    line-height: 28px;
  }
}

// Apply heading styles only for no webflow pages
.app-page-container {
  h1 {
    @include heading-1;
  }

  h2 {
    @include heading-2;
  }

  h3 {
    @include heading-3;
  }

  h4 {
    @include heading-4;
  }

  h5 {
    @include heading-5;
  }

  h6 {
    @include heading-6;
  }
}

@mixin body-large {
  font-family: $font-geomanist;
  font-size: 16px;
  line-height: 24px;
}

@mixin body-small {
  font-family: $font-geomanist;
  font-size: 14px;
  line-height: 22px;
}

@mixin caption {
  font-family: $font-geomanist;
  font-size: 12px;
  line-height: 18px;
}

@mixin gradient-text($bg: $gradient-buttons-linear) {
  background: $bg !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

@mixin gradient-text-reset {
  background: transparent !important;
  background-clip: unset !important;
  -webkit-text-fill-color: unset !important;
}
