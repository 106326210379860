@import 'styles/variables';

.year-picker {
  display: inline-block;

  &__dropdown {
    background-color: transparent;
    border: 0;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding: 0;

    &-icon {
      position: absolute;
      right: -22px;
      top: 50%;
      transform: translateY(-50%);
    }

    &:focus {
      outline: none;
    }
  }

  &__menu {
    height: 372px;
    overflow: auto;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: $color-white;
    z-index: 100;
    box-shadow: 0 2px 10px #5c57662e;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-bottom: 50px;
    padding: 13px 8px;
  }

  &__item {
    background-color: transparent;
    border: 0;
    position: relative;
    color: $color-black;
    font-family: $font-geomanist;
    font-size: 16px;
    cursor: pointer;
    transition: 0.2s linear color;
    padding: 8px 12px;

    &--current {
      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: $color-primary-purple;
        left: 50%;
        top: 2px;
        transform: translateX(-50%);
      }
    }

    &--selected {
      color: $color-gray-9;
      background-color: $color-light-purple;
      border-radius: 24px;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }
  }
}
