@import 'styles/variables';

$default-avatar-size: 38px;

.avatar {
  width: $default-avatar-size;
  height: $default-avatar-size;
  border-radius: 50%;
  position: relative;
  border: 2px solid $color-primary-purple;
}
