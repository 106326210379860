@import 'styles/variables';
@import 'styles/typography';

.tabs {
  &.trip-header-tabs {
    .react-tabs__tab-list {
      color: #342f41;
    }
  }

  .react-tabs {
    &__tab-list {
      @include heading-5;

      font-weight: 600;
      color: $color-medium-grey;
      border-bottom: 1px solid $color-medium-grey;
      padding-bottom: 1px;
      white-space: nowrap;
      margin-bottom: 0;
      overflow-x: auto;
      overflow-y: hidden;
      max-width: 100%;
      display: flex;

      @media #{$breakpoint-md-up} {
        width: fit-content;
      }
    }

    &__tab {
      @include body-small;

      padding: 12px 30px;
      border-width: 0 0 1px;
      display: inline-grid;
      grid-gap: 4px;
      grid-auto-flow: column;
      align-items: center;
      flex-grow: 1;
      border-bottom: 2px solid transparent;

      &-icon {
        margin: 0 auto;
      }

      &-text {
        display: none;

        @media #{$breakpoint-md-up} {
          display: block;
        }
      }

      &--selected {
        background-color: transparent;
        color: $color-primary-purple;
        border-width: 0;
        border-bottom: 2px solid $color-primary-purple;
        border-color: $color-primary-purple !important;
      }

      &:focus {
        box-shadow: none;

        &::after {
          display: none;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      @media #{$breakpoint-md-up} {
        padding: 12px 40px;
        flex-grow: 0;
      }
    }
  }
}
